import {
  type AddOnOutput,
  LotteryId,
} from '~/@types/generated/backend/graphql-schema-types'

const LOTTERIES_WITH_AFTER_PURCHASE_ADD_ON = [
  LotteryId.ColoradoLotto,
  LotteryId.Pick6,
]

const isAddonOutput = (addon: AddOnOutput | string): boolean =>
  !!(addon as AddOnOutput).name && !!(addon as AddOnOutput).value

export const getNormalizedAddOns = (
  addOns: (AddOnOutput | string)[],
  lotteryId: LotteryId,
): string[] => {
  let showAddons = !!addOns.length
  const isAfterPurchaseAddon =
    LOTTERIES_WITH_AFTER_PURCHASE_ADD_ON.includes(lotteryId)

  if (isAfterPurchaseAddon && showAddons) {
    const [addOn] = addOns
    const addOnName = isAddonOutput(addOn) ? (addOn as AddOnOutput).name : addOn
    showAddons = addOnName !== 'MULTIPLIER'
  }

  return showAddons
    ? addOns.map((addOn) =>
        isAddonOutput(addOn) ? (addOn as AddOnOutput).name : (addOn as string),
      )
    : []
}
